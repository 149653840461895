import { makeStyles } from '@itsa.io/ui';

export default makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: 16,
		gap: 24,
	},
}));
