import React, { useContext } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useIntl } from '@itsa.io/web3utils';
import {
	FormControl,
	RadioGroup,
	Radio,
	FormControlLabel,
	Grid,
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@itsa.io/ui';
import newTokenizedPropertyCtx from 'context/newtokenizedproperty';
import useStyles from 'styles/components/properties/property-page/TokenizePropertyStatus';

const TokenizePropertyStatus = () => {
	const { t } = useIntl();
	const { tokenizedProperty, setTokenizedProperty } = useContext(
		newTokenizedPropertyCtx,
	);
	const classes = useStyles();

	const dateNow = new Date();
	const oneMonthFromNow = new Date(new Date().setMonth(dateNow.getMonth() + 1));

	const handleChange = event => {
		tokenizedProperty.status = event.target.value;
		setTokenizedProperty(tokenizedProperty);
	};

	const handleDateChange = date => {
		tokenizedProperty.comingsoonDate = date;
		setTokenizedProperty(tokenizedProperty);
	};

	let datepicker;
	if (tokenizedProperty.status === 'coming soon') {
		datepicker = (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container justifyContent="space-around">
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="MM/dd/yyyy"
						margin="normal"
						id="on-market-date"
						label={t('properties.tokenization.on_market_date')}
						value={tokenizedProperty.comingsoonDate || oneMonthFromNow}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							'aria-label': 'change coming soon date',
						}}
					/>
				</Grid>
			</MuiPickersUtilsProvider>
		);
	}

	const controlColumn = (
		<FormControl className={classes.controlColumn} component="fieldset">
			<RadioGroup
				aria-label="status"
				name="status"
				value={tokenizedProperty.status}
				onChange={handleChange}
			>
				<FormControlLabel
					value="active"
					control={<Radio />}
					label={t('properties.tokenization.active')}
				/>
				<FormControlLabel
					value="coming soon"
					control={<Radio />}
					label={t('properties.tokenization.coming_soon')}
				/>
			</RadioGroup>
			{datepicker}
		</FormControl>
	);

	let infoColumn;
	if (tokenizedProperty.status === 'coming soon') {
		infoColumn = (
			<div className={classes.infoColumn}>
				<h5>Coming Soon</h5>
				<p>
					The property is currently &ldquo;off market&rdquo; and will become
					Active on the On Market Date set below. Only FMLS members will be able
					to view properties listed as Coming Soon and are not to be shown while
					they are in this status. The Coming Soon status is only available at
					the beginning of the listing period. Once a listing is taken out of
					the Coming Soon status, it can not go back into Coming Soon.
				</p>
			</div>
		);
	} else {
		infoColumn = (
			<div className={classes.infoColumn}>
				<h5>Active</h5>
				<p>
					The Active listing status is used when you are ready to submit your
					listing to the MLS. An active listing is assigned an MLS# and is
					available to ALL MLS users as well as the public, via our public
					websistes and all third party sites selected by the broker.
				</p>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			{controlColumn}
			{infoColumn}
		</div>
	);
};

export default TokenizePropertyStatus;
