import React, { useState, useContext } from 'react';
import { useIntl } from '@itsa.io/web3utils';
import { Button } from '@itsa.io/ui';
import PropertyTokenHeader from 'components/properties/PropertyTokenHeader';
import PropertyTokenTabs from 'components/properties/PropertyTokenTabs';
import TokenizePropertyStatus from 'components/properties/property-page/TokenizePropertyStatus';
import TokenizePropertyLocation from 'components/properties/property-page/TokenizePropertyLocation';
import TokenizePropertyListing from 'components/properties/property-page/TokenizePropertyListing';
import TokenizePropertyAgent from 'components/properties/property-page/TokenizePropertyAgent';
import TokenizePropertyLegal from 'components/properties/property-page/TokenizePropertyLegal';
import TokenizePropertyFinancial from 'components/properties/property-page/TokenizePropertyFinancial';
import TokenizePropertyRooms from 'components/properties/property-page/TokenizePropertyRooms';
import TokenizePropertyInterior from 'components/properties/property-page/TokenizePropertyInterior';
import TokenizePropertyExterior from 'components/properties/property-page/TokenizePropertyExterior';
import TokenizePropertySettings from 'components/properties/property-page/TokenizePropertySettings';
import TokenizePropertyGreen from 'components/properties/property-page/TokenizePropertyGreen';
import TokenizePropertyRemarks from 'components/properties/property-page/TokenizePropertyRemarks';
import { LocalHospital as LocalHospitalIcon } from '@material-ui/icons';
import newTokenizedPropertyCtx from 'context/newtokenizedproperty';
import useStyles from 'styles/pages/TokenizeProperty';

const components = [
	TokenizePropertyStatus,
	TokenizePropertyLocation,
	TokenizePropertyListing,
	TokenizePropertyAgent,
	TokenizePropertyLegal,
	TokenizePropertyFinancial,
	TokenizePropertyRooms,
	TokenizePropertyInterior,
	TokenizePropertyExterior,
	TokenizePropertySettings,
	TokenizePropertyGreen,
	TokenizePropertyRemarks,
];

const TokenizePropertyPage = props => {
	const { t } = useIntl();
	const classes = useStyles();
	const { cancelTokenizedProperty } = useContext(newTokenizedPropertyCtx);
	const [propertyTokenTab, setPropertyTokenTab] = useState(0);

	const labels = [
		t('properties.tokenization_steps.status_information'),
		t('properties.tokenization_steps.location_information'),
		t('properties.tokenization_steps.listing_information'),
		t('properties.tokenization_steps.agent_information'),
		t('properties.tokenization_steps.legal'),
		t('properties.tokenization_steps.financial'),
		t('properties.tokenization_steps.rooms'),
		t('properties.tokenization_steps.interior'),
		t('properties.tokenization_steps.exterior'),
		t('properties.tokenization_steps.setting'),
		t('properties.tokenization_steps.green'),
		t('properties.tokenization_steps.remarks_direction'),
	];

	const Component = components[propertyTokenTab];

	return (
		<div className={classes.root}>
			<PropertyTokenHeader />
			<div className={classes.tokenizeContainer}>
				<div className={classes.residentialInput}>
					<LocalHospitalIcon
						className={classes.residentialInputIcon}
						fontSize="inherit"
					/>
					{t('page.tokenize_property.residential_input')}
				</div>
				<PropertyTokenTabs
					onChangeTab={setPropertyTokenTab}
					tab={propertyTokenTab}
				/>
				<div className={classes.labelHeader}>{labels[propertyTokenTab]}</div>
				<Component {...props} />
				<div className={classes.buttonContainer}>
					<Button
						className={classes.cancelButton}
						onClick={cancelTokenizedProperty}
					>
						{t('page.tokenize_property.cancel_input')}
					</Button>
					<Button className={classes.submitButton} onClick={() => {}}>
						{t('page.tokenize_property.submit_property')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TokenizePropertyPage;
