import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@itsa.io/web3utils';
import { ToggleButton, ToggleButtonGroup } from '@itsa.io/ui';
import useStyles from 'styles/components/properties/PropertyTokenTabs';

const PropertyTokenTabs = ({ tab, onChangeTab }) => {
	const { t } = useIntl();
	const classes = useStyles();

	const tabLabels = [
		t('properties.tokenization_steps.status'),
		t('properties.tokenization_steps.location'),
		t('properties.tokenization_steps.listing'),
		t('properties.tokenization_steps.agent_office'),
		t('properties.tokenization_steps.legal'),
		t('properties.tokenization_steps.financial'),
		t('properties.tokenization_steps.rooms'),
		t('properties.tokenization_steps.interior'),
		t('properties.tokenization_steps.exterior'),
		t('properties.tokenization_steps.setting'),
		t('properties.tokenization_steps.green'),
		t('properties.tokenization_steps.remarks'),
	];

	const tabs = tabLabels.map((label, i) => {
		return (
			<ToggleButton aria-label={label} key={label} value={i}>
				{label}
			</ToggleButton>
		);
	});

	const handleTabChange = (event, tabIndex) => {
		// when unpressed, yabIndex becomes "null" -> we do not want an unpressed tab:
		if (typeof tabIndex === 'number') {
			onChangeTab(tabIndex);
		}
	};

	return (
		<ToggleButtonGroup
			aria-label="step"
			className={classes.root}
			exclusive
			onChange={handleTabChange}
			value={tab}
		>
			{tabs}
		</ToggleButtonGroup>
	);
};

PropertyTokenTabs.propTypes = {
	tab: PropTypes.number.isRequired,
	onChangeTab: PropTypes.func.isRequired,
};

export default PropertyTokenTabs;
