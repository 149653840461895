import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		padding: 16,
		gap: 24,
	},
	controlColumn: {
		flexShrink: 0,
	},
	textField: {
		marginTop: 28,
	},
	infoColumn: {
		padding: 4,
	},
	calendarIcon: {
		fill: theme.palette.text.primary,
	},
}));
