import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: theme.palette.backgroundExtreme,
		padding: 16,
	},
}));
