import React, { useContext } from 'react';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import shortenAddress from 'utils/shorten-address';
import useStyles from 'styles/components/properties/PropertyTokenHeader';

const PropertyTokenHeader = () => {
	const { t } = useIntl();
	const { address } = useContext(cryptowalletCtx);
	const classes = useStyles();

	const walletOwner = (
		<div>
			{t('properties.wallet_owner')}: {shortenAddress(address, 5, 5)}
		</div>
	);
	const walletAgent = <div>{t('properties.wallet_listing_agent')}: 0x...</div>;
	const ownerRoyalties = <div>{t('properties.owner_royalties')}: ...%</div>;
	const brokerRoyalties = <div>{t('properties.broker_royalties')}: ...%</div>;

	return (
		<div className={classes.root}>
			{walletOwner}|{walletAgent}|{ownerRoyalties}|{brokerRoyalties}
		</div>
	);
};

export default PropertyTokenHeader;
