import React, { useContext } from 'react';
import { useIntl } from '@itsa.io/web3utils';
import { TextField, Grid } from '@itsa.io/ui';
import newTokenizedPropertyCtx from 'context/newtokenizedproperty';
import useStyles from 'styles/components/properties/property-page/TokenizePropertyFinancial';

const TokenizePropertyFinancial = () => {
	const { t } = useIntl();
	const { tokenizedProperty, setTokenizedProperty } = useContext(
		newTokenizedPropertyCtx,
	);
	const classes = useStyles();

	const handleChange = (field, value) => {
		tokenizedProperty[field] = value;
		setTokenizedProperty(tokenizedProperty);
	};

	return (
		<form
			className={classes.root}
			noValidate
			autoComplete="off"
			onSubmit={e => e.preventDefault()}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
					<TextField
						id="property_street"
						label={t('properties.tokenization.street')}
						onChange={e => handleChange('street', e.target.value)}
						value={tokenizedProperty.street || ''}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
					<TextField
						id="property_street"
						label={t('properties.tokenization.street')}
						onChange={e => handleChange('street', e.target.value)}
						value={tokenizedProperty.street || ''}
					/>
					<TextField
						id="property_street_nr"
						label={`${t('properties.tokenization.street')}#`}
						onChange={e => handleChange('streetNr', e.target.value)}
						value={tokenizedProperty.streetNr || ''}
					/>
					<TextField
						id="property_street_type"
						label={t('properties.tokenization.street_type')}
						onChange={e => handleChange('streetType', e.target.value)}
						value={tokenizedProperty.streetType || ''}
					/>
					<TextField
						id="property_street_unitnr"
						label={t('properties.tokenization.unit_number')}
						onChange={e => handleChange('unitNr', e.target.value)}
						value={tokenizedProperty.unitNr || ''}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
					<TextField
						id="property_city"
						label={t('properties.tokenization.city')}
						onChange={e => handleChange('city', e.target.value)}
						value={tokenizedProperty.city || ''}
					/>
					<TextField
						id="property_county"
						label={t('properties.tokenization.county')}
						onChange={e => handleChange('county', e.target.value)}
						value={tokenizedProperty.county || ''}
					/>
					<TextField
						id="property_state"
						label={t('properties.tokenization.state')}
						onChange={e => handleChange('state', e.target.value)}
						value={tokenizedProperty.state || ''}
					/>
					<TextField
						id="property_country"
						label={t('properties.tokenization.country')}
						onChange={e => handleChange('country', e.target.value)}
						value={tokenizedProperty.country || ''}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
					<TextField
						id="property_school_elementary"
						label={t('properties.tokenization.elementary_school')}
						onChange={e => handleChange('elementarySchool', e.target.value)}
						value={tokenizedProperty.elementarySchool || ''}
					/>
					<TextField
						id="property_school_middle"
						label={t('properties.tokenization.middle_school')}
						onChange={e => handleChange('middleSchool', e.target.value)}
						value={tokenizedProperty.middleSchool || ''}
					/>
					<TextField
						id="property_school_high"
						label={t('properties.tokenization.high_school')}
						onChange={e => handleChange('highSchool', e.target.value)}
						value={tokenizedProperty.highSchool || ''}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

export default TokenizePropertyFinancial;
