import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: 40,
		width: '100%',
	},
	residentialInput: {
		padding: 16,
		fontSize: 24,
	},
	labelHeader: {
		padding: 16,
		fontSize: 18,
	},
	tokenizeContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
		border: 'solid 1px',
		backgroundColor: theme.palette.backgroundExtreme,
	},
	residentialInputIcon: {
		verticalAlign: 'middle',
		marginRight: 8,
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		padding: 16,
		gap: 10,
	},
	cancelButton: {
		backgroundColor: theme.palette.secondary.light,
		color: theme.palette.secondary.dark,
	},
	submitButton: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.dark,
	},
}));
